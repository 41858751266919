import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "../../atoms/Loading/Loading";
import { AuthContext } from '../../../hooks/AuthContext';

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const { role } = useContext(AuthContext);

    // Redirection vers la page de connexion si l'utilisateur n'est pas authentifié
    if (!isLoading && !isAuthenticated) {
        loginWithRedirect();
        return <Loading />;
    }

    // Affichage du chargement tant que l'authentification est en cours
    if (isLoading) {
        return <Loading />;
    }

    // Si l'utilisateur est "admin", on lui donne accès à tout
    if (role === 'admin') {
        return children;
    }

    // Si des rôles sont requis et que le rôle de l'utilisateur ne correspond pas, redirection vers NoAccess
    if (requiredRoles.length > 0 && !requiredRoles.includes(role)) {
        return <Navigate to="/no-access" />;
    }

    return children;
};

export default ProtectedRoute;
