import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './NoAccess.css';

const NoAccess = () => {
    const { logout } = useAuth0();

    return (
        <div className="container" id="no-access">
            <h1>403 - Accès Interdit</h1>
            <p>Vous n'avez pas les permissions nécessaires pour accéder à cette page.</p>
            <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="btn-logout"
            >
                Se Déconnecter
            </button>
        </div>
    );
};

export default NoAccess;
