import React, {useState, useEffect, useCallback} from 'react';
import './Logs.css';
import config from "../../../config";
import Pagination from "../../../components/molecules/Pagination/Pagination";
import {useAuthFetch} from "../../../hooks/useAuthFetch";

const DashboardEmailing = () => {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const authFetch = useAuthFetch();

    const fetchLogs = useCallback(async (page = 1) => {
        try {
            const url = `${config.API_URL}/emailing/log?page=${page}`;
            const response = await authFetch(
                url,
            );
            if (!response.ok) {
                throw new Error(`HTTP error: ${response.status}`);
            }
            const data = await response.json();
            setLogs(data.logs);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Failed to fetch logs:", error);
        }
    }, [authFetch]);


    useEffect(() => {
        fetchLogs(currentPage);
    }, [currentPage, fetchLogs]);

    const handleLogsPageChange = (selectedPage) => {
        setCurrentPage(selectedPage);
        fetchLogs(selectedPage);
    };

    return (
        <div className="DashboardEmailing">
            <div className="DashboardEmailing-content">
                <div className="kpi-section logs">
                    <h2>Logs</h2>
                    <ul>
                        {logs.map(log => (
                            <li key={log.uuid} className="log-item">
                                <strong>{log.create_date}:</strong> {log.message}
                                <span className={`log-level log-level-${log.level.toLowerCase()}`}>
                                    {log.level}
                                </span>
                                {log.context && (
                                    <div>
                                        {JSON.stringify(log.context)}
                                    </div>
                                )}

                            </li>
                        ))}

                    </ul>
                </div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handleLogsPageChange} />
            </div>
        </div>
    );
};

export default DashboardEmailing;