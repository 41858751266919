import React, { useState, useEffect } from 'react';
import config from "../../config";
import Button from "../../components/atoms/Button/Button";
import Breadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import RatesFields from "../../components/molecules/RatesFields/RatesFields";
import TextInput from "../../components/atoms/TextInput/TextInput";
import Loader from "../../components/atoms/Loader/Loader";
import './Prices.css';
import HelpPopUp from "../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../NotificationContext";
import { useAuthFetch } from "../../hooks/useAuthFetch";
import * as XLSX from 'xlsx';

const Prices = () => {
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch();
    const [fixedPrices, setFixedPrices] = useState({
        reference_price: 0,
        decote: 0,
        subscription_price: 0
    });
    const [dynamicFields, setDynamicFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null); // Ajouter l'état pour stocker le fichier sélectionné
    const helpInstructions = [
        "La décote est informative et n'impacte pas le prix de souscription",
        "Laisser un format numérique standard: ex 3.2. Ne pas écrire € ou %",
        "Le format attendu pour l'excel des taux est: Colonne A: Currency, Colonne B: Rate, Colonne C: Local Price",
        "Les colonnes de l'excel doivent être remplies, les valeurs vides ne sont pas autorisées",
        "pas de \",\" pour séparer la partie entière et décimale mais un \".\"",
        "Les en-têtes des colonnes du fichier Excel doivent être exactement comme suit :",
        "Colonne A : Currency",
        "Colonne B : Rate",
        "Colonne C : Local Price"
    ];

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const [pricesResponse, currencyOptionsResponse, ratesResponse] = await Promise.all([
                    authFetch(`${config.API_URL}/emailing/prices`),
                    authFetch(`${config.API_URL}/emailing/prices/currencies`),
                    authFetch(`${config.API_URL}/emailing/rates`),
                ]);

                const errorResponse = [pricesResponse, currencyOptionsResponse, ratesResponse]
                    .find(response => !response.ok);

                if (errorResponse) {
                    const errorData = await errorResponse.json();
                    triggerNotification({
                        type: 'error',
                        content: errorData.error || "Erreur lors de la récupération des données",
                        duration: 3000,
                        persistent: false
                    });
                    setIsLoading(false);
                    return;
                }

                const pricesData = await pricesResponse.json();
                const currencyOptionsData = await currencyOptionsResponse.json();
                const ratesData = await ratesResponse.json();

                setFixedPrices({
                    reference_price: pricesData.reference_price,
                    decote: pricesData.decote,
                    subscription_price: pricesData.subscription_price
                });

                setCurrencyOptions(currencyOptionsData.countries_currencies || []);

                const ratesArray = Array.isArray(ratesData) ? ratesData : [ratesData];
                setDynamicFields(ratesArray.map(rate => ({
                    currency: rate.currency,
                    rate: rate.rate,
                    local_price: rate.local_price
                })));

            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [triggerNotification, authFetch]);

    const handleFixedPriceChange = (e) => {
        setFixedPrices({
            ...fixedPrices,
            [e.target.name]: e.target.value
        });
    };

    const updatePrices = async () => {
        setIsLoading(true);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/prices`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fixedPrices)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue');
            }

            triggerNotification({
                type: 'success',
                content: "Prix mis à jour avec succès",
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const addDynamicField = () => {
        const firstCurrencyOption = currencyOptions.length > 0 ? currencyOptions[0].value : "";

        setDynamicFields([
            ...dynamicFields,
            { currency: firstCurrencyOption, rate: "", local_price: "" }
        ]);
    };

    const handleDynamicFieldChange = (index, field, value) => {
        const updatedFields = [...dynamicFields];
        updatedFields[index][field] = value;
        setDynamicFields(updatedFields);
    };

    const deleteDynamicField = (index) => {
        const updatedFields = dynamicFields.filter((_, i) => i !== index);
        setDynamicFields(updatedFields);
    };

    const submitRates = async () => {
        setIsLoading(true);

        const ratesArray = dynamicFields.map(field => ({
            currency: field.currency,
            rate: field.rate,
            local_price: field.local_price
        }));

        try {
            const response = await authFetch(`${config.API_URL}/emailing/rates`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ratesArray)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || "Échec de la mise à jour des taux");
            }

            triggerNotification({
                type: 'success',
                content: "Taux de change mis à jour avec succès",
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const reader = new FileReader();

        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const ratesArray = jsonData.slice(1).map(row => ({
                currency: row[0],
                rate: row[1],
                local_price: row[2]
            }));

            setIsLoading(true);
            try {
                const response = await authFetch(`${config.API_URL}/emailing/rates`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(ratesArray)
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || "Échec de la mise à jour des taux");
                }

                setDynamicFields(ratesArray);

                triggerNotification({
                    type: 'success',
                    content: "Taux de change mis à jour avec succès",
                    duration: 3000,
                    persistent: false
                });
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
            } finally {
                setIsLoading(false);
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    return (
        <div className="prices-container">
            {isLoading && <Loader />}
            <HelpPopUp instructions={helpInstructions} />
            <Breadcrumb />

            <div className="fixed-prices-form">
                <h2>Prix Fixes</h2>
                <div className="grid-container">
                    <label className="grid-item">Prix de référence (€)</label>
                    <TextInput className="grid-item prices-input" name="reference_price"
                               value={fixedPrices.reference_price} onChange={handleFixedPriceChange} />
                    <label className="grid-item">Décote (%)</label>
                    <TextInput className="grid-item prices-input" name="decote" value={fixedPrices.decote}
                               onChange={handleFixedPriceChange} />
                    <label className="grid-item">Prix de souscription (€)</label>
                    <TextInput className="grid-item prices-input" name="subscription_price"
                               value={fixedPrices.subscription_price} onChange={handleFixedPriceChange} />
                </div>
                <Button onClick={updatePrices}>Mettre à jour les prix</Button>
            </div>

            <div className="dynamic-fields-form">
                <h2>Taux de change</h2>

                {dynamicFields.map((field, index) => (
                    <RatesFields
                        key={index}
                        index={index}
                        field={field}
                        onFieldChange={handleDynamicFieldChange}
                        onDelete={deleteDynamicField}
                        codeOptions={currencyOptions}
                    />
                ))}
                <Button onClick={addDynamicField}>Ajouter un champ</Button>
                <Button onClick={submitRates}>Soumettre les taux</Button>
                <div className="file-upload">
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                    <Button onClick={handleFileUpload} disabled={!selectedFile}>Valider l'upload</Button>
                </div>
            </div>
        </div>
    );
};

export default Prices;
