const config = {
    "OPERATION_NAME":"Esop Capgemini",
    "API_URL":"https://2024.api.esop.capgemini.b-fly.com/",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "KRSOncs63UhmYVn1ztq0ZUIpaKtUN4xi",
    "AUTH0_AUDIENCE": "https://2024.api.esop.capgemini.b-fly.com/",
};

export default config;
