import React, { createContext, useState, useEffect } from 'react';
import { useAuthFetch } from './useAuthFetch';
import config from '../config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const authFetch = useAuthFetch();
    const [authInfo, setAuthInfo] = useState({ role: '', permissions: [] });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAuthInfo = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/auth-info`);
                const authData = await response.json();
                setAuthInfo(authData); // stocke le rôle et les permissions de l'API
            } catch (error) {
                console.error("Failed to fetch auth-info", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAuthInfo();
    }, [authFetch]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};
